/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap";
@import "./app/styles/partials/font";
@import "./app/styles/partials/mixins";
@import "./app/styles/partials/variables";
@import "~@angular/cdk/overlay-prebuilt.css";
@import "./app/styles/partials/rotate-animation";

:root {
  /* Tema Chiaro */
  --primary: #000;
  --dark-blue: #0063C6;
  --light-blue: #0084d6;
  --light-light-blue: rgba(98, 170, 223, 0.5);
  --first-shade-gray: rgba(44, 44, 44, 1);
  --second-shade-gray: rgba(153, 157, 164, 1);
  --third-shade-gray: #c1c6ce;
  --dark-gray: #2C2C2C;
  --light-gray: #eff1f3;
  --light-light-gray: #999DA4;
  --white: #ffffff;
  --font-color: white;
  --active-green: #49d687;
  --form-err-msg: red;
  --down: #d04444;
  --card-background: #ffffff;
	--yellow-alert: #FFD644;
	--orange-not-fair: #FF8372;

  /* Tema Scuro */
  --dark-primary: #fff;
  --dark-dark-blue: #4391D8;
  --dark-light-blue: #4A9CDE;
  --dark-light-light-blue: rgba(98, 170, 223, 0.8);
  --dark-first-shade-gray: #959595;
  --dark-second-shade-gray: #D3D6DC;
  --dark-third-shade-gray: #E8EAEF;
  --dark-dark-gray: #606060;
  --dark-light-gray: #353535;
  --dark-light-light-gray: #707070;
  --dark-white: #000000;
  --dark-font-color: #000000;
  --dark-active-green: #55E89B;
  --dark-form-err-msg: #FF6565;
  --dark-down: #FF6A6A;
  --dark-card-background: #1A1A1A;
}
.dark-theme {
  --primary: var(--dark-primary);
  --dark-blue: var(--dark-dark-blue);
  --light-blue: var(--dark-light-blue);
  --light-light-blue: var(--dark-light-light-blue);
  --first-shade-gray: var(--dark-first-shade-gray);
  --second-shade-gray: var(--dark-second-shade-gray);
  --third-shade-gray: var(--dark-third-shade-gray);
  --dark-gray: var(--dark-dark-gray);
  --light-gray: var(--dark-light-gray);
  --light-light-gray: var(--dark-light-light-gray);
  --white: var(--dark-white);
  --font-color: var(--dark-font-color);
  --active-green: var(--dark-active-green);
  --form-err-msg: var(--dark-form-err-msg);
  --down: var(--dark-down);
  --card-background: var(--dark-card-background);
}

// Color classes
.active-green {
    background-color: var(--active-green, #49d687);
}

.form-err-msg {
    color: var(--form-err-msg, red);
}

.down {
    color: var(--down, #d04444);
}


.yellow-alert {
    color: var(--yellow-alert, #FFD644);
}

.orange-not-fair {
    color: var(--orange-not-fair, #FF8372);
}

.dark-blue {
    color: var(--dark-blue, #0063C6);
}

.light-blue {
    color: var(--light-blue, #0084d6);
}

.light-light-blue {
    color: var(--light-light-blue, rgba(98, 170, 223, 0.5));
}


* {
	margin: 0;
	padding: 0;
	scrollbar-width: thin;
	box-sizing: border-box;
	scrollbar-color: $light-light-gray $light-gray;
	// border: 1px solid black;
}

html,
body {

	width: 100%;
	height: 100%;
	font-family: "Roboto-Regular";
}

a,
a[href] {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}

#hidden__div {
	margin: 0px;
	height: 0px;
	border: none;
	padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0;
}

h5 {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 0;
	line-height: 30px;
}

.super-z-index {
	z-index: 1000000;
}

.table__custom {
	padding: 0px;
	font-weight: font-weight;
	border-top: none;
	white-space: nowrap;
	font-size: 14px;
	vertical-align: middle;

}

.table__custom tr, td {
	line-height: 14px;
	padding: 9px 4px;
}

.table__custom thead  {
	vertical-align: bottom;
	// border-bottom: 1px solid $second-shade-gray;
}



.table__custom th {
	line-height: 14px;
	padding:9px 8px 9px 0px ;
	text-align: center;
	border: 2px solid $white;
}
.table__custom th {
	line-height: 14px;
	padding: 9px 4px;
}
	.table__custom th::before {
    left: 0;
    bottom: 0;
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    background: $light-light-gray;
	}

.modal-content {
	border: 1px solid $light-light-gray;

	.modal-header {
		padding: 16px;
		border-bottom: none;
		position: relative;

		&::after {
			content: "";
			bottom: 0px;
			height: 1px;
			position: absolute;
			background: #dee2e6;
			width: calc(100% - 32px);
		}
	}

	.modal-body {
		padding: 16px;
	}

	.panel-body {
		padding: 8px;
	}
}

.row {
	margin-right: 0;
}

.divider__line {
	height: 1px;
	margin: 10px 0px;
	background: $light-gray;
}

.form-control {
	background: transparent;

	&:focus {
		background: $white;
	}
}

.w-full {

	width: 1000px;
	// height: 100vh;
}

.w-100 {
	width: 100%;
}

.no-wrap {
	flex-wrap: nowrap !important;
}
.text--no-wrap {
	text-wrap: nowrap;
}

// Custom size width bootstrap modal
.custom__large-modal {
	top: 0px;
	left: 25px;
	right: 25px;
	max-width: 1500px;
	position: absolute;
}

.cusotm__table-modal {
	top: 0px;
	left: 25px;
	right: 25px;
	width:max-content;
	max-width: 650px;
	position: absolute;
	display: flex;
	justify-content: center;
}

.modal-95 {
	min-width: 95%;
}

.modal-80 {
	min-width: 80%;
}

// default disabled button
.btn.btn-primary[disabled] {
	background-color: $light-light-gray;
}

.btn:focus,
.btn:active {
	outline: none !important;
	box-shadow: none !important;
}


.btn.btn-primary {
	color: $white;
	font-size: 14px;
	border: none !important;
	background: $first-shade-gray;

	&:active:not(:disabled),
	&:not(:disabled):not(.disabled):active {
		background: $first-shade-gray;
	}
}

.btn.btn-sm {
	gap: 10px;
	padding: 6px;
	font-size: 14px;
	min-width: 60px;
	line-height: 16px;
	align-items: center;
	display: inline-flex;
	justify-content: center;
}

.modal-title {
	color: $primary;
}

input[type="text"] {
	border: 1px solid $third-shade-gray;
}

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
	opacity: 1 !important; /* Firefox */
	color: $third-shade-gray !important;
}

// toastr success color
#toast-container > .toast-success {
	background-color: $primary;
}

.popover-content {
	width: fit-content;
}

.popover {
	width: fit-content !important;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
}
.show {
	min-width: fit-content;
}



.info {
	color: $primary;
	font-size: 14px;
	text-align: center;
}

.field__error {
	margin: 0px;
	color: $down;
	display: block;
	font-size: 14px;
	text-align: left;
	margin-bottom: 8px;
}

.mh--81 {
	max-height: 81px;
}

.grid-container {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.button__icon {
	width: 16px;
	height: 16px;
	filter: $svg-white-filter;

	&--nofilter {
		filter: initial;
	}
}

.modal__buttons {
	gap: 8px;
	display: flex;
	flex-wrap: wrap;
	margin-top: 16px;
	justify-content: flex-end;
}

.active__selection {
	color: $light-blue;
}



.modal-header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

// hide generic reveal password icon for Edge/IE
input::-ms-reveal,
input::-ms-clear {
	display: none;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-track {
	background: $light-gray; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
	background-color: $light-light-gray; /* color of the scroll thumb */
}

.rotate {
	animation: rotation 2s infinite linear;
}

.button__shrinked {
	padding: 0 !important;
	width: 28px !important;
	height: 28px !important;
	min-width: auto !important;
	align-items: center !important;
	display: inline-flex !important;
	justify-content: center !important;

	img {
		margin-right: 0px !important;
	}
}


.mr--4 {
	margin-right: 4px;
}
.mr--8 {
	margin-right: 8px;
}
.mr--16 {
	margin-right: 16px;
}
.ml--0 {
	margin-left: 0px !important;
}
.ml--4 {
	margin-left: 4px;
}
.ml--8 {
	margin-left: 8px;
}
.ml--16 {
	margin-left: 16px;
}

.mb--4 {
	margin-bottom: 4px;
}

.h--28 {
	height: 28px;
}

.h--16 {
	height: 16px;
}
.onDevelop {
	border: 3px dashed orange;
	padding: 1rem;
}

.modalTemplateInfoBubble{
	margin: 20px;
	font-size: 14px;
}



@include media-query($main-breakpoint) {
	html {
		font-size: 15px;
	}
}

@include media-query(1200px) {
	.custom__large-modal {
		left: 5px;
		right: 5px;
	}
}







